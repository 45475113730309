@use '../../settings/_variable' as v;
@use '../../settings/_mixin' as r;
@use '../../settings/_path' as p;
// .p-top {}
//--about--------------------
.p-top-line-bg {
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 110%;
        margin-top: -4%;
        position: absolute;
        background-image: url(p.$path + 'images/top/bg_line_pc.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: -1;
        @include r.sp {
            display: none;
        }
    }
}

.p-top-about {}

.c-top-about {
    margin-top: 150px;
    margin-bottom: 150px;
    display: flex;
    flex-wrap: wrap;
    @include r.sp {
        margin-top: 0;
        margin-bottom: 70px;
    }
}

.c-top-about-intro {
    width: 100%;
    @include r.sp {
        margin-bottom: 50px;
    }
    .c-btn-end {
        display: none;
        @include r.sp {
            margin-top: 20px;
            margin-right: 10px;
            display: flex;
        }
    }
}

.c-top-about-box {
    width: 50%;
    margin-left: 50%;
    margin-top: 100px;
    margin-bottom: 50px;
    @include r.sp {
        margin-top: 130px;
        margin-left: 0;
        width: 100%;
    }
    &__heading {
        margin-bottom: 60px;
        font-size: 1.8rem;
        font-weight: 700; //bold
        letter-spacing: 1.26px;
        line-height: 40px;
        @include r.sp {
            margin-bottom: 30px;
            font-size: 1.3rem;
            letter-spacing: 0.91px;
            line-height: 25px;
        }
    }
    p {
        font-size: 1.8rem;
        letter-spacing: 0.54px;
        line-height: 40px;
        font-weight: 500;
        @include r.sp {
            font-size: 1.2rem;
            letter-spacing: 0.84px;
            line-height: 25px;
        }
    }
}

.c-top-about-image {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__left {
        margin-left: -5%;
        width: 40%;
        @include r.sp {
            margin-left: -10%;
            width: 100%;
        }
    }
    &__right {
        margin-top: 160px;
        margin-right: 10%;
        width: 40%;
        @include r.sp {
            margin-top: 110px;
            margin-left: 5%;
            margin-right: 0;
            width: 100%;
        }
        .c-btn-end {
            margin-top: 80px;
            @include r.sp {
                display: none;
            }
        }
    }
}

//----------------------
.p-top-about-bottom {
    margin-bottom: 245px;
    @include r.sp {
        margin-bottom: 25px;
    }
}

//----------------
.p-top-item-01 {}

.c-top-item-01 {
    margin-bottom: 270px;
    display: flex;
    justify-content: space-between;
    position: relative;
    @include r.sp {
        margin-bottom: 150px;
        flex-direction: column-reverse;
    }
    &__logo {
        width: 28px;
        position: absolute;
        left: -170px;
        z-index: 1;
        @include r.sp {
            display: none;
        }
    }
    &--pudding {
        .c-btn-end {
            @include r.sp {
                margin-right: 50px;
                margin-bottom: 50px;
            }
        }
    }
    &--financie {
        @include r.sp {
            flex-direction: column;
        }
        .c-btn-end {
            margin-right: 50px;
            @include r.sp {
                margin-bottom: 50px;
            }
        }
    }
    &:first-child {
        margin-bottom: 80px;
        @include r.sp {
            margin-bottom: 50px;
        }
    }
    &__left {
        width: 50%;
        @include r.sp {
            width: 100%;
        }
    }
    &__right {
        width: 50%;
        display: flex;
        justify-content: center;
        @include r.sp {
            width: 100%;
        }
    }
    &__image {
        width: 88%;
        display: block;
        @include r.sp {
            margin-left: auto;
            width: 83%;
        }
        &--financie {
            margin-left: auto;
        }
    }
    &__detail {
        width: 78%;
        @include r.sp {
            width: 100%;
        }
        &--financie {
            width: 88%;
            @include r.sp {
                width: 100%;
            }
        }
    }
    &__icon {
        width: 20px;
        margin-top: 33px;
        margin-bottom: 65px;
        @include r.sp {
            width: 11px;
            margin-left: auto;
            margin-right: 25px;
            margin-bottom: 10px;
        }
        &--financie {
            width: 31px;
            margin-top: 40px;
            @include r.sp {
                width: 18px;
                margin-right: 10px;
                margin-bottom: 0;
            }
        }
    }
    &__heading {
        margin-bottom: 27px;
        font-size: 3rem;
        line-height: 50px;
        font-weight: 500;
        @include r.sp {
            margin-bottom: 10px;
            font-size: 1.8rem;
            line-height: 27.5px;
        }
    }
    &__sub_heading_jp {
        display: block;
        font-size: 2.2rem;
        line-height: 2;
        @include r.sp {
            font-size: 1.4rem;
        }
    }
    &__sub_heading {
        margin-top: 0;
        display: block;
        color: v.$main-orange-color;
        font-size: 1.4rem;
        letter-spacing: -0.7px;
        font-weight: 300;
        font-family: v.$font-lexend-exa;
        @include r.sp {
            font-size: 0.7rem;
        }
    }
    &__txt {
        margin-bottom: 80px;
        font-size: 1.8rem;
        letter-spacing: 1.26px;
        // line-height: 2.3;
        font-weight: 500;
        line-height: 40px;
        @include r.sp {
            margin-bottom: 25px;
            font-size: 1.2rem;
            letter-spacing: 1.68px;
            line-height: 26px;
        }
    }
}

//--item category----
.p-top-item-02 {
    margin-bottom: 260px;
    @include r.sp {
        margin-bottom: 100px;
    }
    .c-btn-end {
        justify-content: flex-start;
    }
}

.c-top-item-02-3col-block {
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    @include r.sp {
        margin-bottom: 35px;
        flex-wrap: wrap;
    }
    .c-top-item-02 {
        width: 31%;
        display: block;
        @include r.sp {
            width: 100%;
        }
        &:not(:last-child) {
            @include r.sp {
                margin-bottom: 60px;
            }
        }
        @include r.hover {
            .c-more-btn .c-arrow {
                transform: translateX(20px);
            }
            .c-more-btn::before {
                transform: scale(1);
            }
            @include r.hover {
                .c-image-link::before {
                    opacity: 1;
                }
            }
        }
    }
}

.c-top-item-02 {
    &__image {
        margin-bottom: 37px;
        @include r.sp {
            margin-bottom: 25px;
        }
    }
    &__heading {
        font-size: 2.6rem;
        margin-bottom: 10px;
        font-weight: 500;
        @include r.sp {
            font-size: 1.6rem;
            margin-bottom: 15px;
        }
    }
    &__txt {
        font-size: 1.6rem;
        font-weight: 400;
        margin-bottom: 58px;
        @include r.sp {
            font-size: 1.2rem;
            margin-bottom: 30px;
        }
    }
}

//-------------------
.p-top-news {
    background-color: v.$news-bg-gray-color;
    padding: 115px 0 110px;
    @include r.sp {
        padding: 60px 0 110px;
    }
}

.c-news-title-block {
    display: flex;
    justify-content: space-between;
}

//--insta----------------
.p-top-insta {
    padding: 100px 0;
    @include r.sp {
        padding: 80px 0;
    }
}

.c-insta-title-block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 40px;
    @include r.sp {
        margin-bottom: 20px;
    }
    .c-btn-end {
        @include r.sp {
            display: none;
        }
    }
}

.c-insta-block {
    display: flex;
    gap: 5px; // 左側と右側のギャップ
}

.c-insta-block-box {
    &--left {
        // background-color: burlywood;
        width: 40%;
        @include r.sp {
            display: none;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &--right {
        width: 60%;
        display: flex;
        flex-direction: column;
        //  background-color: #ccc;
        @include r.sp {
            width: 100%;
        }
        .c-insta-block-box__row {
            display: flex;
            gap: 5px; // 画像間のギャップ
        }
    }
    &__item {
        flex: 1;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.p-insta-bottom {
    .c-btn-end {
        display: none;
        @include r.sp {
            margin-top: 30px;
            display: flex;
        }
    }
}
